<template>
  <div id="addNotification" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5"
        >ASIGNACIÓN DE CUESTIONARIO GUÍA I</v-row
      >
      <v-container fluid class="addForms" style="margin-top: 2%">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="4" class="ml-5 formularios">
            <v-row>
              <!--Razon Social-->
              <label for="reason">Razón social</label>
              <select
                name="reason"
                id="reason"
                v-model="quizz.empresaId"
                @change="obtenerAreasPuestos()"
              >
                <option selected disabled value="0">Selecciona Razon</option>
                <option
                  v-for="reason in enterprises"
                  :key="reason.id"
                  v-bind:value="reason.id"
                >
                  {{ reason.razonSocial }}
                </option>
              </select>
            </v-row>
            <v-row>
              <!--Area-->
              <label for="area">Área</label>
              <select
                name="area"
                id="area"
                v-model="quizz.areaId"
                @change="obtenerPuestos()"
              >
                <option selected disabled value="0">Selecciona Area</option>
                <option
                  v-for="area in areas"
                  :key="area.id"
                  v-bind:value="area.id"
                >
                  {{ area.nombreArea }}
                </option>
              </select>
            </v-row>
            <v-row>
              <!--Puesto-->
              <label for="job">Puesto</label>
              <select
                name="job"
                id="job"
                v-model="quizz.puestoId"
                @change="obtenerEmpleados()"
              >
                <option selected disabled value="0">Selecciona Puesto</option>
                <option v-for="job in jobs" :key="job.id" v-bind:value="job.id">
                  {{ job.nombrePuesto }}
                </option>
              </select>
            </v-row>
            <v-row>
              <!--Empleados-->
              <label for="employee">Empleado</label>
              <select name="employee" id="employee" v-model="quizz.empleadoId">
                <option selected disabled value="0">Selecciona Empleado</option>
                <option
                  v-for="employee in employees"
                  :key="employee.id"
                  v-bind:value="employee.id"
                >
                  {{ employee.nombre }} {{ employee.apellidoPaterno }}
                  {{ employee.apellidoMaterno }}
                </option>
              </select>
            </v-row>
            <!--GUIA DE REFERENCIA-->
            <v-row>
              <label for="guia">Cuestionario Ref.</label>
              <select name="guia" id="guia" v-model="quizz.guiaReferencia">
                <option selected disabled value="0"
                  >Seleccione guía de referencia</option
                >
                <option selected value="1">1</option>
                <option selected value="2">2</option>
                <option selected value="3">3</option>
              </select>
            </v-row>
            <v-row>
              <!--Comunicado-->
              <label for="event">Comunicado</label>
              <input
                type="text"
                name="event"
                id="event"
                placeholder="Titulo del Comunicado"
                class="inputs"
                autocomplete="false"
                v-model="quizz.tituloComunicado"
                maxlength="20"
                @keypress="alfaNumerico($event)"
              />
              <br />
              <!--No delete target label-->
              <label></label>
              <textarea
                id="eventText"
                placeholder="Editor de texto..."
                v-model="quizz.descripcionComunicado"
                @keypress="alfaNumerico($event)"
              ></textarea>
            </v-row>
            <v-row>
              <!--Envio-->
              <label for="sender">Envio</label>
              <input
                type="text"
                name="sender"
                id="sender"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="person"
                maxlength="20"
                @keypress="alfaNumerico($event)"
                disabled
              />
            </v-row>
            <v-row>
              <!--Envio-->
              <label for="startDate">Fecha de Inicio</label>
              <input
                type="date"
                name="startDate"
                id="startDate"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="quizz.fechaInicio"
                maxlength="20"
                @keypress="alfaNumerico($event)"
                @blur="validacionFechas()"
              />
            </v-row>
            <v-row>
              <!--Envio-->
              <label for="finishDate">Fecha de Fin</label>
              <input
                type="date"
                name="finishDate"
                id="finishDate"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="quizz.fechaFin"
                maxlength="20"
                @keypress="alfaNumerico($event)"
                @blur="validacionFechas()"
              />
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="7" align-self="center">
            <!--Acciones-->
            <button class="botonAmarillo mt-5 ml-6" @click="validacion()">
              Guardar
            </button>
              <button class="botonAmarilloDerecho" @click="aux">Regresar</button>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Nofificaciones</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="center">{{ respuesta }}</h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de actualizar la información?
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="revisionUltimaAsignacion()"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Notificación</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-sm-center text-md-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="
              confirmation = false;
              aux();
            "
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Alerta de meses-->
    <v-dialog v-model="alerta" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Pronta Asignación</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-sm-center text-md-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="error" text @click="cancelarDuplicidad()"
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer><v-btn
            color="success"
            text
            @click="
              guardar();
            "
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      errors: [],
      respuesta: "",
      advice: false,
      error: false,
      confirmation: false,
      enterprises: [],
      areas: [],
      jobs: [],
      employees: [],
      quizz: {},
      fecha_minimo: "",
      guia: 0,
      person: localStorage.nombreEmpleadoGlobal,
      questions:[],
      questionsAux:[],
      fechaHoy: "",
      fechaProxima: "",
      alerta:false
    };
  },
  methods: {
    cuestionariosExistentes() {
      this.questions = [];
      this.show = true;
      axios
        .get(Server + "/cuestionariosGuiaUno", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.questions = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    cancelarDuplicidad() {
      this.questionsAux = [];
      this.alerta = false;
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      this.fechaProxima = [year, month, day].join("-");
      return [year, month, day].join("-");
    },
    //alerta de la fecha ultima de asignacion
    revisionUltimaAsignacion() {
      this.advice = false;
      //guardar2() si bandera es 1
      //gurdar si bandera es 0
      var dias = 180; // 180 dias = 6 meses para alerta de guia
      //todas las encuestas respondidas
      this.questions.forEach((value) => {
        //la encuesta es por empleado en especifico
        if (this.quizz.empleadoId != 0) {
          if (
            value.guiaReferencia == this.quizz.guiaReferencia &&
            value.empresaId == this.quizz.empresaId &&
            value.empleadoId == this.quizz.empleadoId
          ) {
            var fecha = new Date(value.fechaInicio);
            fecha.setDate(fecha.getDate() + dias);
            this.formatDate(fecha);
            if (this.fechaProxima > this.quizz.fechaInicio) {
              this.questionsAux.push(value);
            }
          }
        }
        //asignacion es por puesto
        else if (this.quizz.empleadoId == 0 && this.quizz.puestoId != 0 && this.quizz.areaId == 0) {
          if (
            value.guiaReferencia == this.quizz.guiaReferencia &&
            value.empresaId == this.quizz.empresaId &&
            value.puestoId == this.quizz.puestoId
          ) {
            var fecha = new Date(value.fechaInicio);
            fecha.setDate(fecha.getDate() + dias);
            this.formatDate(fecha);
            if (this.fechaProxima > this.quizz.fechaInicio) {
              this.questionsAux.push(value);
            }
          }
        }
        //asignacion es por area
        else if (this.quizz.empleadoId == 0 && this.quizz.puestoId == 0 && this.quizz.areaId != 0) {
          if (
            value.guiaReferencia == this.quizz.guiaReferencia &&
            value.empresaId == this.quizz.empresaId &&
            value.areaId == this.quizz.areaId
          ) {
            var fecha = new Date(value.fechaInicio);
            fecha.setDate(fecha.getDate() + dias);
            this.formatDate(fecha);
            if (this.fechaProxima > this.quizz.fechaInicio) {
              this.questionsAux.push(value);
            }
          }
        }
      });
      /*console.log("las encuestas que aun no cumplen los 6 meses");
      console.log(this.questionsAux);*/
      if (this.questionsAux.length > 0) {
        this.alerta = true;
        this.respuesta =
          "El cuestionario ya ha asignado en un lapso menor a 6 meses. ¿Esta seguro de volver asignarlo?.";
      } else {
        this.guardar();
      }
    },
    validacionFechas() {
      if (this.quizz.fechaInicio != "" && this.quizz.fechaFin == "")
        console.log("solo se lleno fecha inicial");
      if (this.quizz.fechaFin != "" && this.quizz.fechaInicio == "")
        console.log("solo se lleno fecha final");
      if (this.quizz.fechaInicio != "" && this.quizz.fechaFin != "") {
        console.log("ambas fechas tienen valors");
        if (this.quizz.fechaInicio > this.quizz.fechaFin) {
          this.titulo = "Fechas Solicitadas";
          this.respuesta =
            "La fecha de inicio debe ser diferente, favor de corregirlo";
          this.error = true;
        }
      }
    },
    guardar() {
      this.alerta = false;
      this.show = true;
      axios
        .put(Server + "/cuestionariosGuiaUno/" + this.quizz.id, this.quizz, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.respuesta = "Dato actualizado con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.quizz = {};
            this.respuesta = "Dato no actualizado";
            this.confirmation = true;
          }
        });
    },
    alfaNumerico: function(evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validacion: function() {
      this.errors = [];
      if (this.quizz.empresaId == 0) {
        this.errors.push("Se debe seleccionar la razón social.");
      }
      if (this.quizz.areaId == 0) {
        this.errors.push("Se debe seleccionar el area.");
      }
      if (this.quizz.puestoId == 0) {
        this.errors.push("Se debe seleccionar el puesto.");
      }
      if (this.quizz.empleadoId == 0) {
        this.errors.push("Se debe seleccionar el empleado.");
      }
      if (this.quizz.empleadoEnviaId == 0) {
        this.errors.push("Se debe especificar persona que envía.");
      }
      if (
        this.quizz.tituloComunicado == "" ||
        this.quizz.tituloComunicado == " "
      ) {
        this.errors.push("Se debe título de comunicado.");
      }
      if (
        this.quizz.descripcionComunicado == "" ||
        this.quizz.descripcionComunicado == " "
      ) {
        this.errors.push("Se debe especificar texto del comunicado.");
      }
      if (this.quizz.fechaInicio == "" || this.quizz.fechaInicio == " ") {
        this.errors.push("Se debe especificar fecha de inicio del comunicado.");
      }
      if (this.quizz.fechaFin == "" || this.quizz.fechaFin == " ") {
        this.errors.push("Se debe especificar fecha de fin del comunicado.");
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    aux() {
      this.advice = false;
      if (this.respuesta == "Dato actualizado con éxito") {
        console.log("congirmacion");
        parseInt(this.quizz.guiaReferencia) == 1
          ? this.$router.push("/guia")
          :  parseInt(this.quizz.guiaReferencia) == 2
          ? this.$router.push("/guiaII")
          : this.$router.push("/guiaIII");
      } else {
        this.guia == 1
          ? this.$router.push("/guia")
          : this.guia == 2
          ? this.$router.push("/guiaIII")
          : this.$router.push("/guiaII");
      }
      this.quizz = {};
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.show = false;
      axios
        .get(Server + "/cuestionariosGuiaUno/" + localStorage.idCuestionario, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.quizz = response.data;
          this.quizz.fechaInicio = this.quizz.fechaInicio.substr(0, 10);
          this.quizz.fechaFin = this.quizz.fechaFin.substr(0, 10);
          this.guia = this.quizz.guiaReferencia;
          this.show = true;
          axios
            .get(Server + "/areas", {
              headers: {
                Authorization: localStorage.token,
              },
            })
            .then((response) => {
              var auxAreas = response.data;
              auxAreas.forEach((value, index) => {
                if (value.empresaId == this.quizz.empresaId) {
                  this.areas.push({
                    id: value.id,
                    nombreArea: value.nombreArea,
                  });
                }
              });
              this.show = false;
            })
            .catch((e) => {
              //console.log(e);
              this.show = false;
              if (e.response.status == 401) {
                this.expiration = true;
              }
            });
          this.show = true;
          axios
            .get(Server + "/puestos", {
              headers: {
                Authorization: localStorage.token,
              },
            })
            .then((response) => {
              var auxAreas = response.data;
              auxAreas.forEach((value, index) => {
                if (value.empresaId == this.quizz.empresaId &&
              value.areaReportaId == this.quizz.areaId) {
                  this.jobs.push({
                    id: value.id,
                    nombrePuesto: value.nombrePuesto,
                  });
                }
              });
              this.show = false;
            })
            .catch((e) => {
              //console.log(e);
              this.show = false;
              if (e.response.status == 401) {
                this.expiration = true;
              }
            });
          this.show = true;
          axios
            .get(Server + "/empleados", {
              headers: {
                Authorization: localStorage.token,
              },
            })
            .then((response) => {
              var todosEmpleados = response.data;
              todosEmpleados.forEach((element) => {
                if (element.empresaId == this.quizz.empresaId && 
              element.puestoId == this.quizz.puestoId &&
              element.areaId == this.quizz.areaId
                ) {
                  this.employees.push(element);
                }
              });
              this.show = false;
            })
            .catch((e) => {
              console.log(e);
              this.show = false;
              if (e.response.status == 401) {
                this.expiration = true;
              }
            });
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerAreasPuestos() {
      this.areas = [];
      this.quizz.areaId = 0;
      this.quizz.puestoId = 0;
      this.quizz.empleadoId = 0;
      this.show = true;
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.quizz.empresaId) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.show = true;
      axios
        .get(Server + "/puestos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.quizz.empresaId) {
              this.jobs.push({
                id: value.id,
                nombrePuesto: value.nombrePuesto,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.show = true;
      axios
        .get(Server + "/empleados", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var todosEmpleados = response.data;
          todosEmpleados.forEach((element) => {
            if (element.empresaId == this.quizz.empresaId) {
              this.employees.push(element);
            }
          });
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerPuestos() {
      this.jobs = [];
      this.quizz.empleadoId = 0;
      this.quizz.puestoId = 0;
      this.show = true;
      axios
        .get(Server + "/puestos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (
              value.empresaId == this.quizz.empresaId &&
              value.areaReportaId == this.quizz.areaId
            ) {
              this.jobs.push({
                id: value.id,
                nombrePuesto: value.nombrePuesto,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerEmpleados() {
      this.employees = [];
      this.quizz.empleadoId = 0;
      this.show = true;
      axios
        .get(Server + "/empleados", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var todosEmpleados = response.data;
          todosEmpleados.forEach((element) => {
            if (
              element.empresaId == this.quizz.empresaId &&
              element.puestoId == this.quizz.puestoId &&
              element.areaId == this.quizz.areaId
            ) {
              this.employees.push(element);
            }
          });
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    fechasMinimo() {
      var fecha = new Date();
      var anio = fecha.getFullYear();
      var dia = fecha.getDate();
      var _mes = fecha.getMonth(); //0 al 11
      _mes = _mes + 1; //de 1 al 12
      if (_mes < 10) {
        //agrega un 0
        var mes = "0" + _mes;
      } else {
        var mes = _mes.toString();
      }
      this.fecha_minimo = anio + "-" + mes + "-" + dia; // Nueva variable
    },
  },
  created() {
    this.fechasMinimo();
    this.listar();
    this.cuestionariosExistentes();
  },
};
</script>
